<template>
  <div
    v-editable="blok"
    class="prose min-h-screen mt-12 md:mt-20 px-3 max-w-3xl mx-auto py-20">
    <h1>{{ blok.title }}</h1>
    <img :src="blok.image_url">
    <p class="lead">{{ blok.excerpt }}</p>
    <rich-text-renderer
      :document="blok.body"
    />
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
