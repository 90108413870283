<template>
  <div>
    <div class="bg-gray-800 pb-32">
      <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="border-b border-gray-700">
            <div class="flex items-center justify-between h-16 px-4 sm:px-0">
              <div class="flex items-center">
                <div class="flex-shrink-0 text-indigo-200 font-normal text-xl">
                  simpl&middot;ql
                </div>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-2">
                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

                    <a href="#read-more" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Read more</a>

                    <a href="#about" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</a>
                  </div>
                </div>
              </div>

              <div class="-mr-2 flex md:hidden">
                <!-- Mobile menu button -->
                <button @click="toggleMobileNav()" class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span class="sr-only">Open main menu</span>
                  <!--
                    Heroicon name: menu

                    Menu open: "hidden", Menu closed: "block"
                  -->
                  <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  <!--
                    Heroicon name: x

                    Menu open: "block", Menu closed: "hidden"
                  -->
                  <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--
          Mobile menu, toggle classes based on menu state.

          Open: "block", closed: "hidden"
        -->
        <div v-if="mobileNav" class="border-b border-gray-700 md:hidden">
          <div class="px-2 py-3 space-y-1 sm:px-3">
            <router-link to="/" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</router-link>

            <a href="#read-more" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Read more</a>

            <a href="#about" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">About</a>
          </div>
        </div>
      </nav>
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-bold text-white">
            Query <strong class="font-bold text-indigo-400">csv</strong>, <strong class="font-bold text-indigo-400">xls</strong> and <strong class="font-bold text-indigo-400">json</strong> with <strong class="font-bold text-indigo-400">SQL</strong>
          </h1>
          <p class="mt-3 font-light text-base text-gray-200 sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl md:mx-0">
            <span class="font-medium">Simplql</span> is a <strong class="font-medium text-white">private and fast</strong> in-browser tool that lets you query your data files with your favourite query language - SQL, without using a database.
          </p>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-2 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div class="md:col-span-2">
            <div class="bg-white border-4 border-white rounded-lg shadow-lg">
              <Editor />

            </div>
          </div>
          <div class="mt-4 md:-mt-8 md:h-full">
            <div class="text-right">
              <a @click="toggleExamples" :class="{'text-indigo-500 md:text-yellow-200': showExamples, 'text-gray-800 md:text-gray-200': !showExamples}" class="cursor-pointer text-sm mb-3 mr-2 block hover:underline">{{ showExamples ? 'Back to tables' : 'Show examples' }}</a>
            </div>
            <div class="bg-white rounded-lg shadow p-2 pt-4 md:px-5 md:pt-6 h-full">
              <div class="rounded-lg h-84">
                <Tables />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-14 md:mt-5">
          <Results />
        </div>

      </div>



      <section id="read-more" class="pb-20 bg-gray-50 border-t border-gray-200 pt-20" style="margin-top: -150px;">
        <div class="max-w-6xl mx-auto pt-20 px-4 sm:px-6 lg:px-8">
          <div class="md:text-center">
            <p class="text-base text-indigo-600 font-semibold tracking-wide uppercase">SIMPLQL</p>
            <h2 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Get your data insights fast
            </h2>
            <p class="mt-4 max-w-2xl text-xl font-light text-gray-700 md:mx-auto">
              Simplql is a private and fast in-browser tool that lets you query your data exports (JSON, CSV, and TSV) with SQL without using a database.
            </p>
          </div>

          <div class="mt-20">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-14 w-14 rounded-md border border-indigo-500 bg-white text-indigo-600">
                    <!-- Heroicon name: globe-alt -->
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Secure &amp; Private
                  </dt>
                  <dd class="mt-2 text-gray-700">
                    Simplql doesn't upload your data files to the server. All the magic is happening within your browser!
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-14 w-14 rounded-md border border-indigo-500 bg-white text-indigo-600">
                    <!-- Heroicon name: scale -->
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Fast
                  </dt>
                  <dd class="mt-2 text-gray-700">
                    Enjoy the speed of memory-based database! Query tables with tens or hundreds of thousands of rows without issues.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-14 w-14 rounded-md border border-indigo-500 bg-white text-indigo-600">
                    <!-- Heroicon name: lightning-bolt -->
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    SQL
                  </dt>
                  <dd class="mt-2 text-base text-gray-700">
                    Use your favourite query language SQL to get insights from your data. Simplql supports most queries that you would normally find within the SQL database.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-14 w-14 rounded-md border border-indigo-500 bg-white text-indigo-600">
                    <!-- Heroicon name: annotation -->
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Do you like it? Have suggestions?
                  </dt>
                  <dd class="mt-2 text-gray-700">
                    Drop me an e-mail <a class="text-indigo-600 hover:underline" href="mailto:hello@simplql.com">hello@simplql.com</a>
                  </dd>
                </div>
              </div>
            </dl>
          </div>

          <div class="w-full mx-auto text-center xl:w-1/2 lg:w-3/4 mt-20">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 mb-8 text-gray-400"
              viewBox="0 0 975.036 975.036">
              <path
                d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
              </path>
            </svg>
            <p class="text-base leading-relaxed md:text-lg">I just found out about Simplql, and tried it out and I just wanted to say that it's awesome what you did. Being a Data Engineer I believe it's the perfect tool to help me out for a lot of analysis.</p>
            <span class="inline-block w-10 h-1 mt-8 mb-6 bg-indigo-500 rounded"></span>
            <h2 class="text-sm font-medium tracking-wider text-gray-900 uppercase title-font">Muhammad</h2>
            <p class="text-gray-500">Data Engineer</p>
          </div>

          <div id="about" class="flex flex-wrap mt-16 pt-16 border-t border-gray-200">
            <div class="w-full md:w-5/12 mr-auto px-4">
              <h2 class="mt-2 text-2xl leading-8 font-medium tracking-tight text-gray-800 sm:text-2xl">
                About
              </h2>
              <!-- <p class="mt-4 max-w-2xl text-xl font-light text-gray-700 lg:mx-auto">
                Simplql is a fast in-browser tool that lets you query your data exports (JSON, CSV, and EXCEL) with SQL without using a database.
              </p> -->

              <p
                class="text-base font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                This tool was built because lack of options to make quick queries to common data files like CSV and JSON using SQL. As a technical marketer, I used to run a custom script or create a database to get my analysis done. Running quick checks on duplicates, analyzing lead data, or validating hypotheses is something that comes up from time to time. But this is just an example from marketing, there are endless options to run quick queries against the datasets. Uses alasql open-source library for SQL. Hope you enjoy it!
              </p>


            </div>
            <div class="w-full md:w-5/12 mr-auto px-4">
              <h2 class="mt-2 text-2xl leading-8 font-medium tracking-tight text-gray-800 sm:text-2xl">
                Author
              </h2>

              <p
                class="text-base font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                You can send me an email hello@simplql.com or join the mailing list and receive some updates about the product and probably some tips/tool suggestions as well.
              </p>

              <div class="mt-10 flex ml-auto mb-4 border border-gray-200 hover:border-indigo-300 bg-white shadow-lg p-4 py-3 rounded-lg sm:-ml-2 sm:-mr-2">
                <div class="sm:flex w-full">
                  <a href="https://www.linkedin.com/in/janarpalk/" target="_blank" ref="nofollow" class="flex items-center no-underline w-full">
                    <div
                      class="text-gray-600 border-2 border-white text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-gray-100"
                    >
                      <img class="rounded-full" src="@/assets/img/janar.jpeg" alt="">
                    </div>
                    <div class="ml-3 flex-1">
                      <p class="font-semibold text-gray-700 text-white leading-tight">Janar Palk</p>
                      <p class="text-gray-600 text-sm leading-tight mt-1">Author of Simplql</p>
                    </div>
                    <div class="">
                      <img class="rounded-full w-8 h-8 border border-white" src="@/assets/img/linkedin.png" alt="">
                    </div>
                  </a>
                </div>
              </div>


            </div>
          </div>

        </div>

        <div class="max-w-lg mx-auto pt-20 px-4 sm:px-6 lg:px-8 text-center mb-20">
          <h2 class="mt-2 mb-8 text-2xl leading-8 font-mediumd tracking-tight text-gray-900 sm:text-3xl">
            Subscribe to simplql mailing list
          </h2>
          <p v-if="newsletterSignupSuccess" class="ml-3 font-medium text-gray-700 truncate">
            Thanks!
          </p>
          <div v-if="!newsletterSignupSuccess" class="rounded shadow-sm">
            <form @submit="newsletterSignup">
              <div class="mt-1 flex rounded shadow-lg border-2 border-gray-200">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                  <input type="text" v-model="newsletterEmail" name="email" id="email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-lg sm:text-sm border-2 border-white" placeholder="Your e-mail">
                </div>
                <button type="submit" class="-ml-2 relative inline-flex items-center space-x-2 px-6 py-3 border-2 border-white text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="max-w-6xl text-center mx-auto sm:px-6 lg:px-8">
          <div class="border-t border-gray-300">
            <div class="flex items-center justify-between h-16 px-4 sm:px-8">
              <div class="flex items-center">
                <div class="flex-shrink-0 text-indigo-600 font-normal text-xl">
                  simpl&middot;ql
                </div>
                <div class="block">
                  <div class="ml-10 flex items-baseline space-x-2">
                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

                    <router-link to="/privacy-policy" class="text-gray-600 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Privacy Policy</router-link>

                    <router-link to="/terms" class="text-gray-600 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Terms of Service</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </main>

    <TableCreateModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Editor from '@/components/Editor.vue'
import Tables from '@/components/Tables.vue'
import Results from '@/components/Results.vue'
import TableCreateModal from '@/components/TableCreateModal.vue'

export default {
  metaInfo: {
    title: 'Query csv, xls and json with SQL - simplql.com',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      { name: 'Simplql is a private and fast in-browser tool that lets you query your data files with your favourite query language - SQL, without using a database.', content: 'Hello', vmid: 'description' }
    ]
  },
  components: {
    Editor,
    Tables,
    Results,
    TableCreateModal
  },
  data() {
    return {
      newsletterEmail: null,
      newsletterSignupSuccess: false,
      mobileNav: false
    }
  },
  computed: {
    ...mapGetters(['getQuery', 'getTables', 'getQueryStatus', 'showExamples'])
  },
  methods: {
    runQuery() {
      this.$store.dispatch('query')
    },

    newsletterSignup(e) {
      if (this.newsletterEmail) {

        var self = this

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/9172493/b06be5b4-3b56-4059-a4c7-5178991f9deb', {
          method: 'POST',
          headers: {
            'Accept': "application/json, text/plain, */*",
            'Content-Type': "application/json;charset=utf-8"
          },
          body: JSON.stringify({
            "fields": [
              {
                "name": "email",
                "value": this.newsletterEmail
              },
              {
                "name": "source_type",
                "value": "newsletter"
              }
            ]
          })
        }).then(function() {
          self.newsletterSignupSuccess = true
        }).catch(function() {

        })
      }

      e.preventDefault();
    },

    toggleExamples() {
      this.$store.dispatch('showExamples')
    },

    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    }
  }
}
</script>
