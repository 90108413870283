<template>
  <div>
    <div>
      <div class="mb-4 mt-10 px-2">
        <div class="flex">
          <div class="flex text-gray-500">
            <span class="text-sm font-medium text-gray-800 relative -bottom-2">
              Results ({{ getQueryResult.rows }}):
            </span>
            <!-- <svg class="w-3 h-3 mt-3 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7" />
            </svg> -->
            <!-- <span class="ml-3 inline-flex items-center px-2.5 py-1 text-xs font-medium text-yellow-800 border-b border-transparent cursor-pointer">
              Errors (0)
            </span> -->
          </div>
          <div class="flex-1 text-right">
            <span @click="queryTable(table.name)" v-for="(table, index) in getTables" v-bind:key="index" :class="{'bg-yellow-100': getRunQuery.indexOf(table.name) > 0}" class="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 cursor-pointer hover:bg-yellow-100">
              {{ table.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="border border-gray-200 border-b-2 border-gray-300 shadow-lg overflow-hidden bg-white rounded-md relative max-h-96">
        <simplebar class="test h-96" data-simplebar-auto-hide="false">
          <table :class="{'opacity-50': getResultFilteringStatus}" class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr class="text-left shadow-md sticky top-0">
                <th class="sticky top-0 px-6 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider bg-gray-200 border-r border-b border-gray-300">
                  #
                </th>
                <th v-for="(item, index) in getQueryResult.columns" v-bind:key="index" :class="{'border-r': getQueryResult.columns.length-1 !== index}"
                  class="sticky top-0 px-6 py-2 text-left text-xs font-medium text-gray-600 tracking-wider bg-gray-200 border-b border-gray-300">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in getQueryResult.values" v-bind:key="index">
                  <td class="border-r border-t border-gray-200 px-3 py-2 text-sm text-center w-12 text-gray-500 whitespace-nowrap">
                    {{ (resultsFrom + index + 1) }}
                  </td>
                  <td v-for="(item, idx) in row" v-bind:key="idx" :class="{'border-r': row.length-1 !== idx, 'bg-gray-100 text-gray-500': item !== 0 && !item}" class="border-t border-gray-200 px-6 py-2 text-sm whitespace-nowrap">
                    {{ item | truncate(50) }}
                  </td>
                </tr>
            </tbody>
          </table>
        </simplebar>
      </div>
      <div class="mt-5 flex flex-col sm:flex-row">
        <div class="flex-1">
          <div v-show="getQueryResult.rows > 500" class="flex-1 flex justify-left">
            <a @click="previousResults()" :disabled="resultsFrom === 0" :class="{'opacity-50': resultsFrom === 0}" class="cursor-pointer relative inline-flex items-center px-2 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:border-gray-400">
              <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 19l-7-7 7-7" />
              </svg>
            </a>
            <span class="ml-4 text-sm leading-9">{{ resultsFrom === 0 ? 1 : resultsFrom }} - {{ resultsTo }}</span>
            <a @click="nextResults()" :disabled="(getQueryResult.rows - resultsTo) <= 0" :class="{'opacity-50': (getQueryResult.rows - resultsTo) <= 0}" class="cursor-pointer ml-4 relative inline-flex items-center px-2 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:border-gray-400">
              <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
        <div class="mt-5 sm:mt-0">
          <div class="relative inline-block text-left">
            <div>
              <button v-click-outside="hideExportDropdown" @click="openExportDropdown()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="options-menu" aria-haspopup="true" aria-expanded="true">
                Export {{ getQueryResult.rows }} rows
                <!-- Heroicon name: chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <div v-if="exportDropdown" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="exportToCSV()" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Export to CSV</a>
                <a @click="exportToJSON()" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Export to JSON</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import { mapGetters } from 'vuex'

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  data() {
    return {
      exportDropdown: false,
      resultsFrom: 0,
      resultsTo: 500
    }
  },

  components: {
    simplebar
  },

  directives: {
    ClickOutside
  },

  computed: {
    ...mapGetters(['getQueryResult', 'getTables', 'getRunQuery', 'getResultFilteringStatus'])
  },

  mounted() {
    this.$store.subscribe(actions => {
      if (actions.type === 'QUERY_RUN_ACTIVE') {
        this.resultsFrom = 0
        this.resultsTo = 500
      }
    })
  },

  // watch: {
  //   'getQueryResult.values': function() {
  //     this.resultsFrom = 0
  //     this.resultsTo = 500
  //   }
  // },

  methods: {
    previousResults () {
      if (this.resultsFrom !== 0) {
        this.resultsFrom = this.resultsFrom - 500
        this.resultsTo = this.resultsTo - 500 < 500 ? 500 : this.resultsTo - 500

        this.$store.dispatch('filterQueryResults', {
          resultsFrom: this.resultsFrom,
          resultsTo: this.resultsTo
        })

        // Tracking
        this.$mixpanel.track('Filter results', {
          action: 'previous',
          resultsFrom: this.resultsFrom,
          resultsTo: this.resultsTo
        })
      }
    },
    nextResults () {
      if ((this.getQueryResult.rows - this.resultsTo) > 0) {
        this.resultsFrom = this.resultsFrom + 500
        this.resultsTo = this.getQueryResult.rows - this.resultsTo > 500 ?  this.resultsTo + 500 : this.resultsTo + this.getQueryResult.rows - this.resultsTo

        this.$store.dispatch('filterQueryResults', {
          resultsFrom: this.resultsFrom,
          resultsTo: this.resultsTo
        })

        // Tracking
        this.$mixpanel.track('Filter results', {
          action: 'next',
          resultsFrom: this.resultsFrom,
          resultsTo: this.resultsTo
        })
      }

    },
    escapeCSV (term) {
      if (term.match && term.match(/,|"/))  {
        return `"${term.replace(/"/g, '""')}"`
      } else {
        return term
      }
    },
    exportToCSV() {
      this.$store.dispatch('exportResults', {
        type: 'csv'
      })
    },

    exportToJSON() {
      this.$store.dispatch('exportResults', {
        type: 'json'
      })
    },

    openExportDropdown() {
      this.exportDropdown = !this.exportDropdown
    },

    hideExportDropdown() {
      this.exportDropdown = false
    },

    queryTable(table) {
      // Tracking
      this.$mixpanel.track('Get table sample', {
      })

      this.$store.dispatch('query', `SELECT * FROM [${table}] LIMIT 100`)
    },

  },

  filters: {
    truncate: function(value, size) {
      if((typeof value === 'string' || value instanceof String) && value !== null) {
        if (value.length <= size) {
          return value
        }

        return value.substr(0, size) + '...'
      } else if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value)
      } else if (value === null) {
        return 'null'
      } else if (!isNaN(value)) {
        return value.toString()
      } else {
        return value
      }
    }
  }
}
</script>
