<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
</style>

<script>
//import * as Comlink from "comlink";



export default {
  created() {
    this.$mixpanel.track('Page load', {
    })
  },
  async mounted() {
    // const worker = new Worker("./workers/parse-url.js", { type: 'module' });
    // const parseURL = Comlink.wrap(worker);
    // console.log(this.$route.query)

    // let params = (new URL(document.location)).searchParams;
    // let filestest = params.get('files')
    // console.log(filestest)

    // var files = JSON.parse(`[${this.$route.query.files}]`)

    // await parseURL.fetchURL(files)
  }
}
</script>
