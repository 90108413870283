<template>
  <div>
    <nav class="bg-white">
      <div class="max-w-5xl mx-auto sm:px-6 lg:px-8">
        <div class="border-b border-gray-200">
          <div class="flex items-center justify-between h-16 px-4 sm:px-0">
            <div class="flex items-center">
              <div class="flex-shrink-0 text-indigo-600 font-normal text-xl">
                <router-link to="/">simpl&middot;ql</router-link>
              </div>
              <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-2">
                  <router-link to="/" class="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</router-link>
                </div>
              </div>
            </div>

            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <button @click="toggleMobileNav()" class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open main menu</span>
                <!--
                  Heroicon name: menu

                  Menu open: "hidden", Menu closed: "block"
                -->
                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <!--
                  Heroicon name: x

                  Menu open: "block", Menu closed: "hidden"
                -->
                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--
        Mobile menu, toggle classes based on menu state.

        Open: "block", closed: "hidden"
      -->
      <div v-if="mobileNav" class="border-b border-gray-200 md:hidden">
        <div class="px-2 py-3 space-y-1 sm:px-3">
          <router-link to="/" class="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</router-link>
        </div>
      </div>
    </nav>
    <div class="max-w-5xl mx-auto sm:px-6 lg:px-8">
      <div id="md_en" data-target="out_en" class="agreement_md pt-20 pb-20">
        <h1 class="text-4xl font-bold text-gray-700 mb-5">Simplql Terms of Service</h1>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">1. Terms</h3>
        <p class="text-gray-800 pb-2">By accessing the website at <a href="https://simplql.com">https://simplql.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">2. Use License</h3>
        <ul class="ml-5 mt-5" type="a">
          <li class="mb-3">Permission is granted to temporarily download one copy of the materials (information or software) on Simplql's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
            <ul class="ml-5" type="i">
              <li class="mb-3">modify or copy the materials;</li>
              <li class="mb-3">use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
              <li class="mb-3">attempt to decompile or reverse engineer any software contained on Simplql's website;</li>
              <li class="mb-3">remove any copyright or other proprietary notations from the materials; or</li>
              <li class="mb-3">transfer the materials to another person or "mirror" the materials on any other server.</li>
            </ul>
            </li>
          <li class="mb-3">This license shall automatically terminate if you violate any of these restrictions and may be terminated by Simplql at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
        </ul>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">3. Disclaimer</h3>
        <ul class="ml-5 mt-5" type="a">
          <li class="mb-3">The materials on Simplql's website are provided on an 'as is' basis. Simplql makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
          <li class="mb-3">Further, Simplql does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
        </ul>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">4. Limitations</h3>
        <p class="text-gray-800 pb-2">In no event shall Simplql or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Simplql's website, even if Simplql or a Simplql authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">5. Accuracy of materials</h3>
        <p class="text-gray-800 pb-2">The materials appearing on Simplql's website could include technical, typographical, or photographic errors. Simplql does not warrant that any of the materials on its website are accurate, complete or current. Simplql may make changes to the materials contained on its website at any time without notice. However Simplql does not make any commitment to update the materials.</p>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">6. Links</h3>
        <p class="text-gray-800 pb-2">Simplql has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Simplql of the site. Use of any such linked website is at the user's own risk.</p>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">7. Modifications</h3>
        <p class="text-gray-800 pb-2">Simplql may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
        <h3 class="text-2xl font-bold text-gray-700 mt-5 mb-5">8. Governing Law</h3>
        <p class="text-gray-800 pb-2">These terms and conditions are governed by and construed in accordance with the laws of Estonia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        <p class="text-gray-800 pb-2"><a href="https://getterms.io" title="Generate a free terms of use document">Terms of Use created with GetTerms.</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data() {
    return {
      mobileNav: false
    }
  },

  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    }
  }
}
</script>
