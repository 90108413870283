import Vue from 'vue'
import Vuex from 'vuex'
import { saveAs } from 'file-saver';
import Papa from 'papaparse'

const Comlink = require("comlink")
const alasqlWrk = Comlink.wrap(new Worker('../workers/alasql.js', { type: 'module' }))


Vue.use(Vuex)

/* eslint-disable */
function slugify(text) {
  return text
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')        // Replace multiple - with single -
    .replace(/-/g, '_')
    .substr(0, 30)
}


/* eslint-disable */

const store = new Vuex.Store({
	state: {
    test: false,
    showTableCreateModal: false,
    sharedFile: {},
    newFile: {},
    newTable: {
      name: '',
      hasHeaders: true,
      sampleData: [],
      sampleDataRaw: [],
      headers: {},
      size: 0,
      file_type: '',
      sample: false,
      rows: 0,
      file: {}
    },
    tables: [],
    query: `-- 1. Add your data file from the right side
-- 2. Write your SQL query here

SELECT * FROM your_table_name
`,
    queryResult: {
      rows: 0,
      columns: [],
      values: []
    },
    queryResultErrors: '',
    runQuery: '',
    queryActive: false,
    resultsFilteringActive: false,
    showExamples: false,
    sampleDataLoading: null
  },
	mutations: {
    INIT_SQL_JS(state, SQL) {
      state.test
      SQL = SQL
    },

		SET_TABLE_CREATE_MODAL(state, modal) {
      state.showTableCreateModal = modal.show
    },

    SET_NEW_FILE(state, file) {
      state.newFile = file

      // Set new table as well
      state.newTable = {
        name: '',
        hasHeaders: true,
        sampleData: [],
        sampleDataRaw: [],
        headers: {},
        size: 0,
        file_type: '',
        sample: file.sample,
        rows: 0,
        file: file
      }

      state.newTable.name = file.table_name
      state.newTable.size = file.size
      state.newTable.file_type = file.display_type
      state.newTable.sampleData = file.data
      state.newTable.sampleDataRaw = file.dataRaw // From CSV as arrays

      file.data.forEach(function(value, index) {
        var i = 1
        Object.keys(value).forEach(function(key) {
          const headerKey = state.newTable.file_type === 'csv' || state.newTable.file_type === 'tsv' ? 'column_' + i : slugify(key)
          if (!state.newTable.headers[headerKey]) {
            state.newTable.headers[headerKey] = {
              name: slugify(key),
              schema: 'string',
              index: false,
              values: []
            }
          }

          if (index < 50) {
            state.newTable.headers[headerKey].values.push(value[key])
          }

          i++;

        })
      })

      Object.keys(state.newTable.headers).forEach(function(key) {
        var numberCount = 0
        var stringCount = 0
        state.newTable.headers[key].values.forEach(function(value) {
          if (value && !isNaN(value)) numberCount++
          if (value && isNaN(value)) stringCount++
        })

        if (numberCount > 3) {
          state.newTable.headers[key].schema = 'number'
        }

        if (stringCount > 3) {
          state.newTable.headers[key].schema = 'string'
        }

      })

    },

    SET_HEADERS_BOOLEAN(state, hasHeaders) {
      state.newTable.hasHeaders = hasHeaders
    },

    SET_HEADERS(state) {
      if (!state.newTable.hasHeaders) {
        Object.keys(state.newTable.headers).forEach(function(key) {
          state.newTable.headers[key].name = key
        })
      } else {
        Object.keys(state.newTable.headers).forEach(function(key) {
          state.newTable.headers[key].name = state.newTable.headers[key].values[0]
        })
      }
    },

    SET_TABLE_NAME(state, name) {
      state.newTable.name = slugify(name.trim())
    },

    CHANGE_HEADER_NAME(state, keyValue) {
      state.newTable.headers[keyValue.key].name = slugify(keyValue.value.trim())
    },

    CHANGE_COLUMN_TYPE(state, keyValue) {
      state.newTable.headers[keyValue.key].schema = keyValue.value.toLowerCase()
    },

    CHANGE_COLUMN_INDEX(state, key) {
      state.newTable.headers[key].index = !state.newTable.headers[key].index
    },

    UPDATE_TABLE_ROWS_LENGTH(state, pl) {
      const index = state.tables.findIndex(table => table.name === pl.tableName)
      Object.assign(state.tables[index], { rows: state.tables[index].rows + pl.rows });
    },

    ADD_NEW_TABLE(state, table) {
      table.rows = 0

      table.isLoading = true // Set new table to loading

      delete table.sampleData
      delete table.sampleDataRaw

      state.tables.push(table)

      // clean up
      state.newTable = {
        name: '',
        hasHeaders: true,
        sampleData: [],
        sampleDataRaw: [],
        headers: {},
        size: 0,
        file_type: '',
        sample: false,
        rows: 0,
        file: {}
      }

      state.newFile = {}
    },

    STOP_TABLE_LOADING_STATE(state, tableName) {
      const index = state.tables.findIndex(table => table.name === tableName)
      Object.assign(state.tables[index], { isLoading: false });
    },

    DROP_TABLE(state, tableName) {
      const index = state.tables.findIndex(table => table.name === tableName)
      state.tables.splice(index, 1)
    },

    SET_QUERY(state, query) {
      state.query = query
    },

    SET_RUN_QUERY(state, query) {
      state.runQuery = query
    },

    SET_QUERY_RESULT(state, result) {
      state.queryResult = result
    },

    SET_QUERY_RESULT_ERRORS(state, errors) {
      state.queryResultErrors = errors
    },

    QUERY_RUN_ACTIVE(state, active) {
      state.queryActive = active
    },

    SET_RESULT_FILTERING_ACTIVE(state, status) {
      state.resultsFilteringActive = status
    },

    TOGGLE_EXAMPLES(state, toggleState) {
      if (toggleState === false || toggleState === true) {
        state.showExamples = toggleState
      } else {
        state.showExamples = !state.showExamples
      }

      Vue.prototype.$mixpanel.track('Show examples', {
        show: state.showExamples
      })
    },

    SAMPLE_DATA_LOADING(state, fileName) {
      state.sampleDataLoading = fileName
    }

  },

  getters: {

    getHeadersBoolean(state) {
      return state.newTable.hasHeaders
    },

    getTableName(state) {
      return state.newTable.name
    },

    headers(state) {
      return state.newTable.headers
    },

    newTable(state) {
      return state.newTable
    },

    db(state) {
      state.test
      return db
    },

    getTables(state) {
      return state.tables
    },

    getQuery(state) {
      return state.query
    },

    getRunQuery(state) {
      return state.runQuery
    },

    getQueryResult(state) {
      return state.queryResult
    },

    queryResultErrors(state) {
      return state.queryResultErrors
    },

    getQueryStatus(state) {
      return state.queryActive
    },

    normalizeText: () => (text) => {
      return slugify(text)
    },

    getResultFilteringStatus(state) {
      return state.resultsFilteringActive
    },

    showExamples(state) {
      return state.showExamples
    },

    getSampleDataLoading(state) {
      return state.sampleDataLoading
    }

  },

  actions: {
    showTableCreateModal({ commit }) {
      commit('SET_TABLE_CREATE_MODAL', {
        show: true
      })
    },

    hideTableCreateModal({ commit }) {
      commit('SET_TABLE_CREATE_MODAL', {
        show: false
      })
    },

    addNewFile({ commit }, file) {
      commit('SET_NEW_FILE', file)
    },

    setHeadersBoolean({ commit }, value) {
      commit('SET_HEADERS_BOOLEAN', value)
      commit('SET_HEADERS')
    },

    setTableName({ commit }, value) {
      commit('SET_TABLE_NAME', value)
    },

    changeHeaderName({ commit }, keyValue) {
      commit('CHANGE_HEADER_NAME', keyValue)
    },

    changeColumnType({ commit }, keyValue) {
      commit('CHANGE_COLUMN_TYPE', keyValue)
    },

    changeColumnIndex({ commit }, key) {
      commit('CHANGE_COLUMN_INDEX', key)
    },

    // initSampleDB({ commit, state }, library) {
    //   return new Promise(async (resolve) => {
    //     if (!library) {

    //       var xhr = new XMLHttpRequest();
    //       xhr.open('GET', '/data/demo.db', true);
    //       xhr.responseType = 'arraybuffer';

    //       xhr.onload = e => {
    //         var uInt8Array = new Uint8Array(xhr.response);
    //         commit('CREATE_DATABASE', uInt8Array)
    //         var contents = db.exec("SELECT * FROM spotify_sample LIMIT 10");

    //         var schema = {}
    //         var int = ['positivity']

    //         contents[0].columns.forEach(function(value, key) {
    //           if (int.includes(value)) {
    //             schema[key] = 'INTEGER'
    //           } else {
    //             schema[key] = 'TEXT'
    //           }
    //         })

    //         const file = {
    //           name: 'spotify_sample',
    //           type: 'text/csv',
    //           display_type: 'csv',
    //           size: 0,
    //           data: [],
    //           sample: true,
    //           table_name: 'spotify_sample',
    //           headers: contents[0].columns,
    //           schema: schema
    //         }

    //         // var test = db.exec("pragma table_info(spotify_sample)");
    //         // console.log(test)

    //         commit('SET_NEW_FILE', file)
    //         commit('ADD_NEW_TABLE', state.newTable)

    //         resolve()
    //       };
    //       xhr.send();

    //     } else if (library == 'alasql') {
    //       commit('CREATE_ALA_DATABASE')

    //       alasql('CREATE TABLE `spotify_sample`')

    //       //await alasql('SELECT * INTO spotify_sample FROM CSV("/data/data.csv", {headers:true})')

    //       for(let i = 1; i < 15; i++) {
    //         alasql('SELECT * INTO spotify_sample FROM CSV("/data/spotify/data-' + i + '.csv", {headers:true})')
    //       }

    //       alasql('ALTER TABLE spotify_sample ADD COLUMN valence number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN year number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN acousticness number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN artists string');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN danceability number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN duration_ms number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN energy number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN explicit number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN id string PRIMARY KEY');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN instrumentalness number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN key number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN liveness number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN loudness number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN mode number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN name string');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN popularity number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN release_date string');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN speechiness number');
    //       alasql('ALTER TABLE spotify_sample ADD COLUMN tempo number');

    //       const table = alasql.tables.spotify_sample

    //       let schema = {}
    //       let headers = {}
    //       let indexes = {}

    //       table.columns.forEach(function(value, key) {
    //         headers[key] = value.columnid
    //         schema[key] = value.dbtypeid
    //         indexes[key] = false
    //       })

    //       const file = {
    //         name: 'spotify_sample',
    //         type: 'text/csv',
    //         display_type: 'csv',
    //         size: 0,
    //         data: [],
    //         sample: true,
    //         table_name: 'spotify_sample',
    //         headers: headers,
    //         schema: schema
    //       }

    //       commit('SET_NEW_FILE', file)
    //       commit('ADD_NEW_TABLE', state.newTable)

    //       resolve()
    //     }
    //   })
    // },

    // finishSampleDB() {
    //   return new Promise(async (resolve) => {
    //     for(let i = 15; i < 36; i++) {
    //       alasql('SELECT * INTO spotify_sample FROM CSV("/data/spotify/data-' + i + '.csv", {headers:true})')
    //     }

    //     resolve()
    //   })
    // },

    // getTableCount({ commit }, tableName) {
    //   // const count = alasql.tables[tableName].data.length

    //   // commit('UPDATE_TABLE_ROWS_LENGTH', { tableName: tableName, rows: count })

    //   return alasql.tables[tableName].data.length
    // },

    // initSqlJsAction() {
    //   return new Promise((resolve) => {
    //     const config = {
    //       locateFile: filename => `https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.4.0/dist/${filename}`
    //     }

    //     initSqlJs(config).then(function(res) {
    //       SQL = res
    //       resolve()
    //     })
    //   })
    // },

    deleteTable({ commit, state }, tableName) {
      dbExec({
        exec: `DROP TABLE ${tableName}`,
        execType: 'deleteTable',
        tableName: tableName
      })

      const table = state.tables.find(x => x.name === tableName)
      let i = 0
      Object.keys(table.headers).forEach(function(key) {
        if (table.headers[key].index === true) {
          i++
          dbExec({
            exec: `DROP INDEX column_${i}_index_${tableName}`,
            execType: 'deleteIndex',
            tableName: tableName
          })
        }
      })

      // Tracking
      Vue.prototype.$mixpanel.track('Drop table', {
        indexCount: i,
        columnsCount: Object.keys(table.headers).length
      })

      commit('DROP_TABLE', tableName)
    },

    async createTable({ commit, state }) {

      // eslint-disable-next-line no-unused-vars
      let columns = '', i = 1

      Object.keys(state.newTable.headers).forEach(function(key) {
        if (state.newTable.headers[key].name.length < 1) {
          commit('CHANGE_HEADER_NAME', { key: key, value: 'column_' + i })
        }

        columns += '`' + state.newTable.headers[key].name + '` ' + state.newTable.headers[key].schema + (i !== Object.keys(state.newTable.headers).length ? ', ' : '')

        i++
      })

      const exec = await dbExec({
        exec: 'CREATE TABLE `' + state.newTable.name + '` (' + columns + ');',
        execType: 'createTable',
        tableName: state.newTable.name
      })

      if (exec.errors.length) {
        commit('STOP_TABLE_LOADING_STATE', exec.tableName)
        return exec
      }

      i = 0
      Object.keys(state.newTable.headers).forEach(function(key) {
        if (state.newTable.headers[key].index === true) {
          i++
          dbExec({
            exec: `CREATE INDEX column_${i}_index_${state.newTable.name} ON [${state.newTable.name}] ([${state.newTable.headers[key].name}] ASC)`,
            execType: 'createIndex',
            tableName: state.newTable.name
          })
        }
      })

      // Tracking
      Vue.prototype.$mixpanel.track('Create table', {
        hasHeaders: state.newTable.hasHeaders,
        indexCount: i,
        columnsCount: Object.keys(state.newTable.headers).length,
        fileDisplayType: state.newTable.file_type,
        fileType: state.newTable.file.type,
        fileSize: Number((state.newTable.file.size / (1024 * 1024)).toFixed(2))
      })

      return {
        errors: []
      }
    },

    deleteTableIndexes({state}) {
      let i = 0
      Object.keys(state.newTable.headers).forEach(function(key) {
        i++

        dbExec({
          exec: `DROP INDEX column_${i}_index_${state.newTable.name}`,
          execType: 'deleteIndex',
          tableName: state.newTable.name
        })
      })
    },

    insertTableDataDb({ commit}, data) {

      try {
        var chunk = (typeof data.chunk === 'string' || data.chunk instanceof String) ? JSON.parse(`[${data.chunk}]`) : data.chunk;

        commit('UPDATE_TABLE_ROWS_LENGTH', { rows: chunk.length, tableName: data.tableName })

        dbExec({
          exec: `SELECT * INTO [${data.tableName}] FROM ?`,
          execType: 'insertChunk',
          chunk: chunk,
          tableName: data.tableName
        })
      } catch (e) {
      }

    },

    removeTable({ commit }, table) {
      commit.test
    },

    setQuery({ commit }, query) {
      commit('SET_QUERY', query)
    },

    queryResult({ commit, state }, pl) {
      let res = pl.res, errors = pl.errors

      commit('QUERY_RUN_ACTIVE', false)
      commit('SET_RESULT_FILTERING_ACTIVE', false)

      // ${!pl.query.includes('LIMIT ') ? ' LIMIT 500' : ''}
      commit('SET_RUN_QUERY', `${pl.query}`)

      if (errors) {
        commit('SET_QUERY_RESULT_ERRORS', errors)
      } else {
        commit('SET_QUERY_RESULT_ERRORS', false)

        if (res.values.length == 0) {
          commit('SET_QUERY_RESULT_ERRORS', 'No results found')
        }

        commit('SET_QUERY_RESULT', res)
      }
    },

    query({ commit, state }, query) {
      commit('QUERY_RUN_ACTIVE', true)
      commit('SET_RESULT_FILTERING_ACTIVE', true)
      var runQuery = query ? query : state.query

      dbQuery({
        query: `${runQuery}`
      })
    },

    cancelQuery({ commit }) {
      commit('QUERY_RUN_ACTIVE', false)
      dbCancelQuery()
    },

    newFilePreview(_, data) {
      const worker = createParseWorker()

      // Tracking
      Vue.prototype.$mixpanel.track('File preview', {
        fileDisplayType: data.fileMeta.display_type,
        fileType: data.file.type,
        fileSize: Number((data.file.size / (1024 * 1024)).toFixed(2))
      })

      sharedFile = data
      worker.postMessage(data)
    },

    async addDataToTable({ commit, state }) {
      const table = state.newTable
      commit('ADD_NEW_TABLE', table)

      if (!table.sample) {
        sharedFile.table = table

        const worker = createParseWorker()

        sharedFile.excerpt = false
        worker.postMessage(sharedFile)
      } else {
        commit('TOGGLE_EXAMPLES', false)

        const worker = Comlink.wrap(
          new Worker("../workers/worker.js", {type: 'module'})
        );

        // Tracking
        Vue.prototype.$mixpanel.track('Load sample data', {
          file: table.file.name,
        })

        let chunkNthInsertCounter = 0
        await worker.fetchFileFromURL(table.file.url, false, table.headers, table.file_type, table.hasHeaders, Comlink.proxy(function(chunk, isEnd) {
          if (isEnd) {
            commit('STOP_TABLE_LOADING_STATE', table.name)
          }

          store.dispatch('insertTableDataDb', { chunk: chunk, tableName: table.name })
          if (chunkNthInsertCounter === 1) {
            store.commit('SET_QUERY', `-- Write your SQL query here

SELECT * FROM [${table.name}]
            `)

            store.dispatch('query')
          }

          chunkNthInsertCounter++
        }))
      }

    },

    stopLoadingTable({ commit }, data) {
      commit('STOP_TABLE_LOADING_STATE', data.tableName)
    },

    filterQueryResults({ commit }, pl) {
      commit('SET_RESULT_FILTERING_ACTIVE', true)
      dbGetFilteredResults(pl)
    },

    exportResults(_, pl) {
      dbExport(pl)
    },

    async loadExample() {
      const data =  await (await fetch('/data/example.json')).json()
      await dbCreateExample(data)
      store.dispatch('setQuery', `-- 1. Add your data file from the right side
-- 2. Write your SQL query here

SELECT * FROM example_books`)
      store.dispatch('query')
    },

    async loadTutorial(_, pl) {
      if (pl.type === 'select_object_array') {
        const data =  await (await fetch('/data/tutorials/objects_and_arrays.json')).json()
        dbCreateTutorial(pl.type, data)
        store.dispatch('setQuery', `
/* To run an example, remove "--" from the beginning of the query */

-- SELECT * FROM tutorial

/* Select by object key with value. Remove "--" from next line */

SELECT * FROM tutorial WHERE options->canDelete = 'true'

/* Select where array contains "admin" */

-- SELECT * FROM tutorial WHERE 'admin' IN rights`)

        store.dispatch('query')
      }

      if (pl.type === 'find_duplicates') {
        const data =  await (await fetch('/data/tutorials/find_duplicates.json')).json()
        await dbCreateTutorial(pl.type, data)
        store.dispatch('setQuery', `
/* Find duplicates (email) from table "tutorial" with fields user_id, name, email */

SELECT name, email
FROM tutorial
WHERE email in
  (
    SELECT email FROM tutorial
    GROUP BY email
    HAVING COUNT(*) > 1
  )`)

        store.dispatch('query')
      }

      if (pl.type === 'join_tables') {
        const data =  await (await fetch('/data/tutorials/join_books.json')).json()
        await dbCreateTutorial(pl.type, data)
        store.dispatch('setQuery', `
-- SELECT book_id, publication_year, author, title FROM tutorial
-- SELECT user_id, book_id, rating FROM tutorial2

/* Using LEFT JOIN */

SELECT
  author,
  title,
  AVG(t2.rating) AS avg_rating
FROM tutorial AS t1
LEFT JOIN tutorial2 AS t2
  ON t1.book_id = t2.book_id
GROUP BY title, author`)

        store.dispatch('query')
      }
    },

    async loadSampleData({ commit }, file) {
      // const worker = new Worker("./workers/parse-url.js", { type: 'module' });
      // const parseURL = Comlink.wrap(worker);

      // await parseURL.fetchLocalFile('/data/' + fileName)

      commit('SAMPLE_DATA_LOADING', file.fileName)

      const worker = Comlink.wrap(
        new Worker("../workers/worker.js", {type: 'module'})
      );

      const fileArr = file.fileName.split('.')

      let fileType
      if (fileArr[1] === 'json') {
        fileType = 'application/json'
      } else if (fileArr[1] === 'csv') {
        fileType = 'text/csv'
      }

      const fileMeta = {
        display_type: fileArr[1],
        name: file.fileName,
        size: 1,
        table_name: fileArr[0] + '_sample',
        type: fileType,
        sample: true,
        url: file.fileURL
      }

      await worker.fetchFileFromURL(file.fileURL, true, {}, fileMeta.display_type, false, Comlink.proxy(function(res) {

        // TODO: move to worker
        if (fileMeta.display_type === 'csv' || fileMeta.display_type === 'tsv') {
          fileMeta.dataRaw = res
          fileMeta.data = convertCSVArrayToObject(res)
        } else if (fileMeta.display_type === 'json') {
          fileMeta.data = res
        }

        commit('SAMPLE_DATA_LOADING', null)
        store.dispatch('addNewFile', fileMeta)
        store.dispatch('showTableCreateModal', { sample: true })
      }))
    },

    async addSampleData({ commit, state }) {
      const table = state.newTable
      commit('ADD_NEW_TABLE', table)
    },

    showExamples({ commit }) {
      commit('TOGGLE_EXAMPLES')
    }

  }
})

const dbQuery = async (pl) => {
  const result = await alasqlWrk.alasqlQuery(pl)
  store.dispatch('queryResult', result)
}

const dbGetFilteredResults = async (pl) => {
  const result = await alasqlWrk.getFilteredResults(pl)
  store.commit('SET_QUERY_RESULT', result)
  store.commit('SET_RESULT_FILTERING_ACTIVE', false)
}

const dbExec = async (pl) => {
  const exec = await alasqlWrk.alasqlExec(pl)
  return exec;
}

const dbCreateTutorial = async (tutorial, data) => {
  await alasqlWrk.alasqlCreateTutorial(tutorial, data)
  return true;
}

const dbCreateExample = async (data) => {
  await alasqlWrk.alasqlCreateExample(data)
  return true;
}

const dbExport = async (pl) => {
  const values = await alasqlWrk.alasqlGetQueryValues()

  let fileName = 'simplql_export.' + pl.type;
  let fileToSave = null;

  if (pl.type === 'json') {
    fileToSave = new Blob([JSON.stringify(values, null, 4)], {
      type: 'application/json',
      name: fileName
    });
  }

  if (pl.type === 'csv') {
    const values = await alasqlWrk.alasqlGetQueryValues()
    const csv = Papa.unparse(values)
    const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

    if (navigator.msSaveBlob) {
      fileToSave = navigator.msSaveBlob(csvData, fileName);
    }
    else {
      fileToSave = window.URL.createObjectURL(csvData);
    }
  }

  saveAs(fileToSave, fileName);
}


var sharedFile = {}
var chunkNthInsertCounter = 1 // For doing a sample query for first chunk
function createParseWorker() {
  const parseFile = new Worker('../workers/parse-file.js', { type: 'module' });

  parseFile.onmessage = e => {

    if (e.data.action === 'addNewFile') {

      if (e.data.fileMeta.display_type === 'csv' || e.data.fileMeta.display_type === 'tsv') {
        e.data.fileMeta.dataRaw = e.data.data
        e.data.fileMeta.data = convertCSVArrayToObject(e.data.data)
      }

      if (e.data.fileMeta.display_type === 'json') {
        //e.data.fileMeta.data = convertObjectToHeadersRows(e.data.data)
        e.data.fileMeta.data = e.data.data
      }

      if (e.data.fileMeta.display_type === 'xls' || e.data.fileMeta.display_type === 'xlsx') {
        //e.data.fileMeta.data = convertObjectToHeadersRows(e.data.data)
        e.data.fileMeta.data = e.data.data
      }

      store.dispatch('addNewFile', e.data.fileMeta)
      store.dispatch('showTableCreateModal')
    }

    if (e.data.action === 'insertData') {
      store.dispatch('insertTableDataDb', e.data)
      if (store.state.tables.length === 1 && chunkNthInsertCounter === 1) {
        store.commit('SET_QUERY', `-- Write your SQL query here

SELECT * FROM [${e.data.tableName}] LIMIT 100
        `)
        store.dispatch('query')
      }
      chunkNthInsertCounter++
    }

    if (e.data.action === 'stopLoadingTable') {
      store.dispatch('stopLoadingTable', e.data)
    }
  };

  return parseFile
}

function convertCSVArrayToObject(data) {
  const hasHeaders = store.getters.getHeadersBoolean
  const headers = data[0]

  let newData = []

  for (let i = 0; i < data.length; i++) {
    let obj = {}

    data[i].forEach(function(value, key) {
      if (hasHeaders) {
        obj[headers[key]] = value
      } else {
        obj['column_' + (key + 1)] = value
      }

    })

    newData.push(obj)
  }

  return newData

  console.log(newData)
}

store.dispatch('loadExample')

export default store;
