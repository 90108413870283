<template>
  <div v-editable="blok">
    <rich-text-renderer
      :document="blok.content"
    />
  </div>
</template>

<script>
export default {
  name: "snippet-rich-text",
  props: {
    blok: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style scoped>
* {
  @apply text-gray-800;
}
p {
  @apply mb-4;
}

p b {
  @apply font-semibold;
}

ol li p {
  @apply mb-2;
}

a {
  @apply underline;
}

a:hover {
  @apply no-underline;
}

h3 {
  @apply text-xl leading-6 font-medium text-gray-900 mb-3;
}

ol {
  @apply list-decimal ml-10;
}
</style>
