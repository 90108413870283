<template>
  <li>
    <div v-if="!getTables.length">
      <div @click="newFile()" class="flex group relative rounded-lg shadow-xs cursor-pointer hover:shadow-md">
        <input @change="previewFile" class="hidden" ref="fileInput" id="fileInput" type="file" name="file" accept=".csv,.json,.tsv" />
        <div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-green-500 border border-green-600 shadow-xs">
          <svg class="animate-pulse h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </div>
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-green-600 bg-white rounded-r-md truncate">
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a href="#" class="text-gray-900 font-medium">Add a new file</a>
            <div>
              <p class="sm:inline text-xs text-gray-500">CSV, JSON and TSV supported</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="newFile()" class="flex justify-end" v-if="getTables.length">
      <input @change="previewFile" class="hidden" ref="fileInput" id="fileInput" type="file" name="file" accept=".csv,.json,.tsv" />
      <button
        class="text-center items-center px-2 py-1.5 text-gray-700 text-sm font-medium rounded-md bg-gray-50 border border-gray-300 focus:none cursor-pointer hover:shadow-md hover:border-gray-400">
        <div class="inline-flex items-center">
          <span class="mr-2 ml-1">Add new file</span>
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </div>
      </button>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex"

Number.prototype.countDecimals = function () {
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0;
}

export default {
  data() {
    return {
      file: {},
      isLoading: false,
      interval1: null
    }
  },
  computed: {
     ...mapGetters(['normalizeText', 'getTables'])
  },

  async mounted() {

  },

  methods: {

    isMacintosh() {
      return navigator.platform.indexOf('Mac') > -1
    },

    isWindows() {
      return navigator.platform.indexOf('Win') > -1
    },

    newFile: function() {
      this.$refs.fileInput.click()
    },

    previewFile: function(event) {
      //this.isLoading = true
      this.file.name = event.target.files[0].name
      this.file.type = event.target.files[0].type
      this.file.size = event.target.files[0].size
      this.file.table_name = this.normalizeText(this.file.name)
      this.file.data = []

      if (!this.file.type) {
        this.file.type = 'text/csv'
      }

      if (this.file.type === 'text/csv') {
        this.file.display_type = 'csv'
      }

      if (this.file.type === 'text/tab-separated-values') {
        this.file.display_type = 'tsv'
      }

      if (this.file.type === 'application/json') {
        this.file.display_type = 'json'
      }

      if (this.file.type === 'application/vnd.ms-excel') {
        if (this.isWindows) {
          this.file.type = 'text/csv'
          this.file.display_type = 'csv'
        } else {
          this.file.display_type = 'xls'
        }

      }

      if (this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.file.display_type = 'xlsx'
      }

      this.file = {...this.file, ...event.target.files[0]}

      console.log(this.file, event.target.files[0])

      this.$store.dispatch('newFilePreview', {
        file: event.target.files[0],
        fileMeta: this.file,
        excerpt: true
      })

      this.$refs.fileInput.value = ''

    },
  }
}

</script>
