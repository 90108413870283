import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/styles.css'

Vue.config.productionTip = false

import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import StoryblokVue from 'storyblok-vue'
Vue.use(StoryblokVue)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
Vue.use(VueRichTextRenderer)

import Page from '@/components/storyblok/Page'
Vue.component('page', Page)

import Teaser from '@/components/storyblok/Teaser'
Vue.component('teaser', Teaser)

import BlogPage from '@/components/storyblok/BlogPage'
Vue.component('BlogPage', BlogPage)

import RichText from '@/components/storyblok/RichText'
Vue.component('RichText', RichText)


import VueMixpanel from 'vue-mixpanel'
Vue.use(VueMixpanel, {
  token: process.env.NODE_ENV === 'development' ? '2e0ea10016404b5bd42db38b4bf34ce3' : 'a93dd2e16a253b9a0d2bbc65b68843f7',
  config: {
    debug: process.env.NODE_ENV === 'development' ? true : false
  }
})

// var alasql = require('alasql');
// const ala = {
//   install () {
//     Vue.prototype.alasql = alasql
//   }
// }
// Vue.use(ala)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
