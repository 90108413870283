<template>
  <div>
    <pre>
      <BlogPage :blok="story.content"/>
    </pre>
  </div>
</template>

<script>
// 1. Require the Storyblok client
import StoryblokClient from 'storyblok-js-client'

// 2. Set your token - you will be able to create a new space later.
const token = 'i6zStF5E2nTeTPrHyNjelAtt';

// 3. Initialize the client with the preview token so we can access our API easily
// from your space dashboard at https://app.storyblok.com
let storyapi = new StoryblokClient({
  accessToken: token
})

export default {
  name: 'HelloWorld',
  data () {
    return {
      story: {
        content: {
          body: []
        }
      }
    }
  },
  created () {
    // 4. Initialize the Storyblok Client Bridge to allow us to subscribe to events
    // from the editor itself.
    window.storyblok.init({
      accessToken: token
    })
    window.storyblok.on('change', () => {
      // this will indicate to load the home story, exchange that with the full slug of your content
     // either it is the page URL or hardcoded as in the example below
      this.getStory('blog/test-blog-entry', 'draft')
    })
    window.storyblok.pingEditor(() => {
      if (window.storyblok.isInEditor()) {
        this.getStory('blog/test-blog-entry', 'draft')
      } else {
        this.getStory('blog/test-blog-entry', 'published')
      }
    })
  },

  methods: {
    getStory(slug) {
      storyapi.get('cdn/stories/' + slug)
      .then((response) => {
        console.log(response.data)
        this.story = response.data.story
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }
}
</script>
