<template>
  <div>

    <simplebar v-show="showExamples" style="height: 22rem" class="overflow-y-auto -mr-4" data-simplebar-auto-hide="false">
      <div class="mb-4 -mt-2 mr-4">
        <!-- <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
            <option selected>Datasets</option>
            <option>Tutorials</option>
          </select>
        </div> -->
        <div>
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex" aria-label="Tabs">
              <a @click="changeExampleType('datasets')" :class="{'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': exampleType !== 'datasets', 'border-indigo-500 text-indigo-600': exampleType === 'datasets'}" class="cursor-pointer flex-1 py-2 px-1 text-center border-b-2 font-medium text-sm" aria-current="page">
                Datasets
              </a>
              <a @click="changeExampleType('tutorials')" :class="{'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': exampleType !== 'tutorials', 'border-indigo-500 text-indigo-600': exampleType === 'tutorials'}" class="cursor-pointer flex-1 py-2 px-1 text-center border-b-2 font-medium text-sm">
                Tutorials
              </a>
            </nav>
          </div>
        </div>
      </div>

      <ul v-show="exampleType === 'tutorials'" class="space-y-4 mr-4">
        <li @click="loadTutorial({ type: 'find_duplicates' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-indigo-800">
                    Find duplicates
                  </p>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:ml-4 sm:text-right text-gray-400">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-1 text-sm">Show users with same email from list of names and emails</div>
          </div>
        </li>
        <li @click="loadTutorial({ type: 'select_object_array' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-indigo-800">
                    SELECT object and array items
                  </p>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:ml-4 sm:text-right text-gray-400">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-1 text-sm">Example of querying columns with object {key: value} or array [0,1,2] data</div>
          </div>
        </li>
        <li @click="loadTutorial({ type: 'join_tables' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-indigo-800">
                    Join two tables
                  </p>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:ml-4 sm:text-right text-gray-400">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-1 text-sm">Example of using LEFT JOIN to get average rating for each book.</div>
          </div>
        </li>
      </ul>

      <ul v-show="exampleType === 'datasets'" class="space-y-4 mr-4">
        <li @click="loadSampleData({ fileName: 'spotify.json', fileURL: 'https://simplql.nyc3.cdn.digitaloceanspaces.com/sample-data/spotify.json' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="mr-3">
                <img class="h-10 w-10 rounded-md shadow-md" src="@/assets/sample-logos/spotify.png" />
              </div>
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-gray-900 leading-6">
                    Spotify
                  </p>
                  <div class="text-gray-500 text-xs">
                    <p class="inline">19 columns</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">170653 rows</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">93.7mb</p>
                  </div>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right text-gray-400">
                <svg v-if="getSampleDataLoading !== 'spotify.json'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
                <svg v-if="getSampleDataLoading === 'spotify.json'" class="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-3 text-sm">Sample Spotify dataset from 2017</div>
          </div>
        </li>

        <li @click="loadSampleData({ fileName: 'goodreads_books.csv', fileURL: 'https://simplql.herokuapp.com/https://simplql.nyc3.cdn.digitaloceanspaces.com/sample-data/goodreads_books.csv' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="mr-3">
                <img class="h-10 w-10 rounded-md shadow-md" src="@/assets/sample-logos/goodreads.png" />
              </div>
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-gray-900 leading-6">
                    Goodreads books
                  </p>
                  <div class="text-gray-500 text-xs">
                    <p class="inline">8 columns</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">10000 rows</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">2.1mb</p>
                  </div>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right text-gray-400">
                <svg v-if="getSampleDataLoading !== 'goodreads_books.csv'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
                <svg v-if="getSampleDataLoading === 'goodreads_books.csv'" class="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-3 text-sm">10000 books from Goodreads with an average rating</div>
          </div>
        </li>

        <li @click="loadSampleData({ fileName: 'goodreads_ratings.csv', fileURL: 'https://simplql.herokuapp.com/https://simplql.nyc3.cdn.digitaloceanspaces.com/sample-data/goodreads_ratings.csv' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="mr-3">
                <img class="h-10 w-10 rounded-md shadow-md" src="@/assets/sample-logos/goodreads.png" />
              </div>
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-gray-900 leading-6">
                    Goodreads book ratings
                  </p>
                  <div class="text-gray-500 text-xs">
                    <p class="inline">3 columns</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">981756 rows</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">11.9mb</p>
                  </div>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right text-gray-400">
                <svg v-if="getSampleDataLoading !== 'goodreads_ratings.csv'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
                <svg v-if="getSampleDataLoading === 'goodreads_ratings.csv'" class="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-3 text-sm">Goodreads user ratings with book_id and rating</div>
          </div>
        </li>

        <li @click="loadSampleData({ fileName: 'dt_tweets.csv', fileURL: 'https://simplql.herokuapp.com/https://simplql.nyc3.cdn.digitaloceanspaces.com/sample-data/dt_tweets.csv' })" class="group relative bg-white rounded-lg shadow-sm cursor-pointer">
          <div class="rounded-lg border border-gray-300 bg-white px-3 py-4 hover:border-indigo-500">
            <div class="flex">
              <div class="mr-3">
                <img class="h-10 w-10 rounded-md shadow-md" src="@/assets/sample-logos/twitter.png" />
              </div>
              <div class="flex flex-1 items-center">
                <div class="text-sm">
                  <p class="font-medium text-gray-900 leading-6">
                    Donald Trump tweets
                  </p>
                  <div class="text-gray-500 text-xs">
                    <p class="inline">21 columns</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">970919 rows</p>
                    <span class="inline mx-1" aria-hidden="true">&middot;</span>
                    <p class="inline">461.4mb</p>
                  </div>
                </div>
              </div>
              <div class="mt-2 text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right text-gray-400">
                <svg v-if="getSampleDataLoading !== 'dt_tweets.csv'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
                <svg v-if="getSampleDataLoading === 'dt_tweets.csv'" class="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
            <div class="text-gray-700 mt-3 text-sm">US Election 2020 Tweets. Oct 15th 2020 - Nov 8th 2020</div>
          </div>
        </li>
      </ul>
    </simplebar>
      <ul v-show="!showExamples" class="space-y-4">
        <li v-for="(table, index) in getTables" v-bind:key="index" v-on:mouseenter="hover(index)" v-on:mouseleave="hoverOut(index)" class="flex group relative rounded-lg shadow-sm cursor-pointer">
          <div v-if="popoverVisible[index]" class="pr-3 absolute z-40 -top-3 cursor-auto" style="left: -95%;" transition="fade" v-on:mouseenter="hoverInfo(index)" v-on:mouseleave="hoverOutInfo(index)">
            <div class="bg-gray-100 border-2 border-gray-300 shadow-md block z-50 font-normal leading-normal text-sm w-80 text-left no-underline break-words rounded-lg">
              <div>
                <div class="bg-gray-900 text-white opacity-75 font-semibold p-3 px-4 mb-0 border-b border-solid border-gray-200 rounded-t-md">
                  {{ table.name }}
                </div>
                <div class="bg-gray-50 py-1 rounded-b-md">
                  <dl>
                    <div v-for="(header, index) in table.headers" v-bind:key="index" class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-900 col-span-2">
                        {{ header.name }}
                      </dt>
                      <dd class="mt-1 text-right text-sm text-gray-500 sm:mt-0 col-span-1 italic" :class="{'underline': header.index}">
                        {{ header.schema }}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-semibold rounded-l-md bg-gray-100 border border-gray-200" :class="{'text-green-600': ['xls', 'xlsx'].includes(table.file_type), 'text-gray-600': ['csv', 'tsv'].includes(table.file_type), 'text-blue-600': table.file_type === 'json'}">
            {{ table.file_type.toUpperCase() }}
          </div>
          <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div class="flex-1 px-4 py-2 text-sm truncate">
              <a href="#" class="text-gray-900 font-medium hover:text-gray-600">{{ table.name }}</a>
              <div>
                <p class="sm:inline text-gray-500">{{ Object.keys(table.headers).length }} columns</p>
                <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                <p class="sm:inline text-gray-500">{{ table.rows }} rows</p>
              </div>
            </div>
            <div class="flex-shrink-0 pr-2">
              <button v-show="!table.isLoading" @click="deleteTable(table.name)" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-red-800 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Delete</span>
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>

              <svg v-show="table.isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>

            </div>
          </div>
        </li>

        <FileUpload />

      </ul>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
import { mapGetters } from 'vuex'

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  data() {
    return {
      exampleType: 'datasets',
      popoverVisible: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false
      },
			timer: '',
      isInInfo: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false
      }
    }
  },
  components: {
    FileUpload,
    simplebar
  },
  computed: {
    ...mapGetters(['getTables', 'showExamples', 'getSampleDataLoading'])
  },
  mounted() {
  },
  created() {
    this.$store.subscribe((mutation) => {
      if(mutation.type === 'STOP_TABLE_LOADING_STATE') {
        this.$forceUpdate()
      }
    })
  },
  methods: {

    loadSampleData: function(file) {
      this.$store.dispatch('loadSampleData', file)

      // Tracking
      this.$mixpanel.track('Load sample file', {
        file: file.fileName
      })
    },

    loadTutorial(type) {
      this.$store.dispatch('loadTutorial', type)

      // Tracking
      this.$mixpanel.track('Load tutorial', {
        tutorial: type
      })
    },

    changeExampleType(type) {
      this.exampleType = type
    },

    deleteTable(table) {
      this.$store.dispatch('deleteTable', table)
    },

    hover: function(index)
    {
      let vm = this;
      this.timer = setTimeout(function() {
        vm.showPopover(index);
      }, 100);
    },

    hoverOut: function(index)
    {
      let vm = this;
      clearTimeout(vm.timer);
      this.timer = setTimeout(function() {
        if(!vm.isInInfo[index])
        {
          vm.closePopover(index);
        }
      }, 100, index);
    },

    hoverInfo: function(index)
    {
      this.isInInfo[index] = true;
    },

    hoverOutInfo: function(index)
    {
      this.isInInfo[index] = false;
      this.hoverOut(index);
    },

    showPopover: function(index)
    {
      this.popoverVisible[index] = true;
    },

    closePopover: function(index)
    {
      this.popoverVisible[index] = false;
    }
  }
}
</script>
