<template>
  <div>
    <nav class="bg-white">
      <div class="max-w-5xl mx-auto sm:px-6 lg:px-8">
        <div class="border-b border-gray-200">
          <div class="flex items-center justify-between h-16 px-4 sm:px-0">
            <div class="flex items-center">
              <div class="flex-shrink-0 text-indigo-600 font-normal text-xl">
                <router-link to="/">simpl&middot;ql</router-link>
              </div>
              <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-2">
                  <router-link to="/" class="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</router-link>
                </div>
              </div>
            </div>

            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <button @click="toggleMobileNav()" class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open main menu</span>
                <!--
                  Heroicon name: menu

                  Menu open: "hidden", Menu closed: "block"
                -->
                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <!--
                  Heroicon name: x

                  Menu open: "block", Menu closed: "hidden"
                -->
                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--
        Mobile menu, toggle classes based on menu state.

        Open: "block", closed: "hidden"
      -->
      <div v-if="mobileNav" class="border-b border-gray-200 md:hidden">
        <div class="px-2 py-3 space-y-1 sm:px-3">
          <router-link to="/" class="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</router-link>
        </div>
      </div>
    </nav>
    <div class="max-w-5xl mx-auto sm:px-6 lg:px-8">
      <div id="md_en" data-target="out_en" class="agreement_md pt-20 pb-20">
        <h1 class="text-4xl font-bold text-gray-700 mb-5">Privacy Policy</h1>
        <p class="text-gray-800 pb-2">Your privacy is important to us. It is Simplql's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://simplql.com">https://simplql.com</a>, and other sites we own and operate.</p>
        <p class="text-gray-800 pb-2">We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
        <p class="text-gray-800 pb-2">We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
        <p class="text-gray-800 pb-2">We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p class="text-gray-800 pb-2">Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p class="text-gray-800 pb-2">You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p class="text-gray-800 pb-2">Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p class="text-gray-800 pb-2">This policy is effective as of 15 January 2021.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data() {
    return {
      mobileNav: false
    }
  },

  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    }
  }
}
</script>
