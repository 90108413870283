<template>

  <div class="relative rounded-lg h-96 overflow-hidden">
    <div class="w-full h-full overflow-hidden" ref="editor" id="editor" data-gramm_editor="false"></div>
    <div class="w-full flex p-2 -mt-15 relative -top-12 z-10">
      <div class="flex-1">
        <div v-if="queryResultErrors" class=" text-sm p-2 mr-2 bg-gray-600 text-gray-50 rounded-md">
          <p class="-mb-1 leading-5">Error: {{ queryResultErrors }}</p>
        </div>
      </div>
      <div>
        <button
          :disabled="getQueryStatus"
          @click.prevent="runQuery()"
          type="button"
          class="w-28 text-center items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <template v-if="!getQueryStatus">Run query</template>
            <template v-if="getQueryStatus">
              <div class="inline-flex items-center">
                <span class="mr-4">Running</span>
                <svg class="animate-spin -ml-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CodeMirror from 'codemirror'
require('codemirror/mode/sql/sql');

export default {
  data() {
    return {
      query: '',
      queryRunning: false,
      startTime: null,
      runTime: null,
      timerInterval: null,
      queryTime: 0
    }
  },

  watch: {
    getQueryStatus (value) {
      this.queryRunning = value

      if (value === true) {
        this.startTime = Date.now()
        this.startQueryTimer()
      } else {
        if (this.startTime) {
          clearInterval(this.timerInterval)
          this.queryTime = 0
          this.runTime = ((Date.now() - this.startTime) / 1000).toFixed(2)
        }
      }
    },

    getQuery (value, oldValue) {
      if (value !== oldValue) {
        this._editor.setValue(value)
      }

    }
  },

  computed: {
    ...mapGetters(['getQuery', 'getTables', 'getQueryStatus', 'queryResultErrors', 'getRunQuery'])
  },

  methods: {
    runQuery() {
      this.queryRunning = true

      // Tracking
      this.$mixpanel.track('Run query', {
        query: this._editor.getValue(),
      })

      this.$store.dispatch('setQuery', this._editor.getValue())
      this.$store.dispatch('query')
    },

    startQueryTimer() {
      this.timerInterval = setInterval(() => {
        this.queryTime = this.queryTime + 0.1
      }, 100);
    },

    confirmLeave() {
      return window.confirm('Do you really want to leave? All data will be lost!')
    },

    confirmStayInDirtyForm() {
      return
    },

    beforeWindowUnload(e) {
      console.log('unload')
      if (!this.confirmLeave()) {
        e.preventDefault()
        e.returnValue = ''
      }
    }


  },

  created() {
    //window.addEventListener('beforeunload', this.beforeWindowUnload.bind(this))
  },

  mounted() {

    const editor = this.$refs.editor
    const self = this

    this._editor = new CodeMirror(editor, {
      value: this.getQuery,
      mode:  "sql",
      theme: 'seti',
      tabSize: 2,
      lineWrapping: true
    });

    this._keyListener = function(e) {
      if (((e.key === "s") && (e.ctrlKey || e.metaKey))) {
        e.preventDefault();
        self.runQuery();
      }
    }

    document.addEventListener('keydown', this._keyListener.bind(this))


  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
    document.removeEventListener('beforeunload', this._beforeWindowUnload)
  }
}
</script>

<style lang="scss">
  @import '~codemirror/lib/codemirror.css';
  @import '~codemirror/theme/seti.css';

  #editor .cm-s-seti {
    background: #282a36 !important;
    font-family: 'Source Code Pro', monospace !important;
    height: 100%;
    padding-bottom: 50px;
  }

  .cm-s-seti span.cm-comment {
    color: #788b94;
  }

  #editor {
    background: #282a36;
    border-radius: 6px;
    font-family: 'Source Code Pro', monospace;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 22px;
    padding: 15px;
    tab-size: 2;
    resize: none !important;
    min-height: 100%;
    height: 100%;
    color: #eee
  }

  #editor .CodeMirror-scroll {
    overflow-x: hidden !important;
  }

</style>
