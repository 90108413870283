<template>
  <div
    v-if="showModal"
    class="service-modal fixed z-10 inset-0 overflow-y-auto"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="absolute md:relative top-0 left-0 right-0 inline-block align-bottom bg-white md:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white">
          <div class="sm:flex sm:items-start">
            <div class="text-left w-full">
              <div
                id="modal-headline"
                class="flex border-b border-cool-gray-200 p-4 md:pl-8"
              >
                <div class="flex items-center flex-1">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Create table
                  </h3>
                </div>
                <div
                  @click="hideModal()"
                  class="cursor-pointer flex justify-center items-center w-8 h-8 text-gray-500"
                >
                  <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div class="px-4 md:px-8 py-5 pb-8 bg-gray-100 border-b border-gray-200">

                <div class="max-w-sm">
                  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
                    <label for="table_name" class="block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2">
                      Table name
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input type="text" v-model="tableName" name="table_name" id="table_name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md">
                    </div>
                  </div>

                  <div v-if="newTable.file_type !== 'json'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
                    <label for="has_headers" class="block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2">
                      File has headers
                    </label>
                    <div class="mt-1 sm:mt-2 sm:col-span-2">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input v-model="headersBoolean" id="has_headers" name="has_headers" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 rounded">
                          <span class="ml-3 text-sm text-gray-600">Unselect if first row contains data</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="errors.length > 0" class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: x-circle -->
                    <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      There were {{ errors.length }} errors with your submission
                    </h3>
                    <div class="mt-2 text-sm text-red-700">
                      <ul class="list-disc pl-5 space-y-1">
                        <li v-for="(item, index) in errors" v-bind:key="index">
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="md:px-8 pb-5">
                <div class="flex flex-col md:mt-10">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block max-w-full w-full sm:px-6 lg:px-8">
                      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <simplebar class="test h-96" data-simplebar-auto-hide="false">
                          <table class="max-w-full w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th scope="col" class="sticky top-0 px-4 py-3 text-left text-xs font-medium bg-gray-800 text-gray-100 uppercase tracking-wider w-44">
                                  Column name
                                </th>
                                <th scope="col" class="sticky top-0 px-4 py-3 text-left text-xs font-medium bg-gray-800 text-gray-100 uppercase tracking-wider">
                                  Example value
                                </th>
                                <th scope="col" class="sticky top-0 px-4 py-3 text-left text-xs font-medium bg-gray-800 text-gray-100 uppercase tracking-wider">
                                  Field type
                                </th>
                                <th scope="col" class="sticky top-0 px-4 py-3 text-left text-xs font-medium bg-gray-800 text-gray-100 uppercase tracking-wider text-center w-20">
                                  Index
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(header, key) in headers" v-bind:key="key" class="border-b border-gray-200">
                                <td class="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-44">
                                  <input :ref="'header_' + key" @keyup="changeHeaderName(key)" :value="header.name" type="text" class="w-full block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
                                </td>
                                <td class="px-4 py-3 text-sm text-gray-700 truncate whitespace-normal">
                                  {{ header.values[1] | truncate(30) }}
                                </td>
                                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 italic font-medium">
                                  <select :ref="'column_' + key" @change="changeColumnType(key)" class="mt-1 block w-full pl-3 pr-10 py-1 text-base border-transparent bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                    <option value="string" :selected="header.schema === 'string'">String</option>
                                    <option value="number" :selected="header.schema === 'number'">Number</option>
                                  </select>
                                </td>
                                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 italic font-medium text-center w-20">
                                  <input ref="column_index" @change="changeColumnIndex(key)" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 rounded">
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </simplebar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <div class="flex-1 text-right">
            <button
              @click="saveTable()"
              id="save"
              type="button"
              class="w-full text-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <template v-if="isLoading">
                <svg class="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </template>
              <template v-if="!isLoading">
                Create table
              </template>
            </button>
          </div>
          <div class="flex-1">
            <!-- <button
              @click="removeTable('remove')"
              id="remove"
              type="button"
              class="mt-3 w-full text-left justify-left px-2 py-2 text-base font-medium text-red-700 hover:underline focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
            >
              Remove table
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'TableCreateModal',
  data() {
    return {
      errors: [],
      isLoading: false
    }
  },

  components: {
    simplebar
  },

  computed: {
    showModal() {
      const showModal = this.$store.state.showTableCreateModal

      if (showModal) {
        this.initTableCreateModal()
      }

      return showModal
    },

    headersBoolean: {
      get() {
        return this.getHeadersBoolean()
      },
      set(v) {
        this.setHeadersBoolean(v)
      }
    },

    tableName: {
      get() {
        return this.getTableName()
      },
      set(v) {
        return this.setTableName(v)
      }
    },

    ...mapGetters(['headers', 'newTable'])

  },

  created() {

  },

  methods: {
    hideModal() {
      this.errors = []
      this.$store.dispatch('hideTableCreateModal')
    },

    removeTable() {

    },

    async saveTable() {
      const table = await this.$store.dispatch('createTable')
      if (table.errors.length) {
        this.errors = table.errors

        // Tracking
        this.$mixpanel.track('Create table error', {
          errors: table.errors
        })
      } else {
        this.$store.dispatch('addDataToTable')
        this.hideModal()
      }

    },

    initTableCreateModal() {
      // var self = this

      // Object.keys(this.headers).forEach(function(key) {
      //   console.log(self.headers[key].values[0])

      //   var obj = {
      //     key: key,
      //     value: !self.headers[key].values[0] ? 'string' : isNaN(self.headers[key].values[0]) ? 'string' : 'number'
      //   }

      //   self.$store.dispatch('changeColumnType', obj)
      // })

    },

    changeHeaderName(key) {
      const value = this.$refs['header_' + key][0].value
      this.$store.dispatch('changeHeaderName', { key: key, value: value })
    },

    changeColumnIndex(key) {
      this.$store.dispatch('changeColumnIndex', key)
    },

    changeColumnType(key) {
      const value = this.$refs['column_' + key][0].value
      this.$store.dispatch('changeColumnType', { key: key, value: value })
    },

    ...mapActions(['setHeadersBoolean', 'setTableName']),
    ...mapGetters(['getHeadersBoolean', 'getTableName'])
  },

  filters: {
    truncate: function(value, size) {
      if((typeof value === 'string' || value instanceof String) && value !== null) {
        if (value.length <= size) {
          return value
        }

        return value.substr(0, size) + '...'
      } else if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value).substr(0, size) + '...'
      } else if (value === null) {
        return 'null'
      } else if (!isNaN(value)) {
        return value.toString()
      } else {
        return value
      }
    }
  }
}
</script>

<style scoped>
</style>
